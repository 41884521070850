<template>
    <div class="MemberMonthSettleDetail">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-row>
                <el-col :span="4">结算机构: {{ form.deptName }}</el-col>
                <el-col :span="4">结算月份: {{ form.settleYearAndMonth }}</el-col>
                <el-col :span="4">创建人: {{ form.creator }}</el-col>
                <el-col :span="8">创建时间: {{ form.createTime }}</el-col>
                <el-col :span="4">
                    <el-checkbox v-model="emptyZero">不显示'0'</el-checkbox>
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <el-button type="primary" @click="handleAudit" size="small">审核详情</el-button>

            <PickupReturnAudit
                ref="PickupReturnAudit"
                :review-privilege-flag="'menu.report.member.monthSettle.review'"
            />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table border stripe style="width: 100%" :data="tableData" size="mini" :highlight-current-row="true">
                <el-table-column label="序号" width="60" type="index" />
                <el-table-column label="会员名称" width="80" prop="memberName" />
                <el-table-column label="手机号" width="100" prop="memberMobile" />
                <el-table-column label="充值" header-align="center">
                    <el-table-column label="收取工本费" width="100" prop="inWorkCost" :formatter="mTwo" />
                    <el-table-column label="充值笔数" width="100" prop="rechargeCount" :formatter="mCount" />
                    <el-table-column
                        label="充值金额"
                        width="100"
                        prop="rechargeMoney"
                        :formatter="mTwo"
                        align="right"
                    />
                </el-table-column>
                <el-table-column label="退款" header-align="center">
                    <el-table-column
                        label="支出工本费"
                        width="100"
                        prop="outWorkCost"
                        :formatter="mTwo"
                        align="right"
                    />
                    <el-table-column label="退款笔数" width="100" prop="refundCount" :formatter="mCount" />
                    <el-table-column label="退款金额" width="100" prop="refundMoney" :formatter="mTwo" align="right" />
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from '../../../../js/Util';
import PickupReturnAudit from 'components/PickupReturnAudit';

export default {
    name: 'MemberMonthSettleDetail',
    props: ['form'],
    components: { PickupReturnAudit },
    data() {
        return {
            emptyZero: true,
            tableData: [],
            settle: {
                monthSettleCode: this.form.code,
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            baseUrl: '/member/monthSettle',
            url: '/member/monthSettleDetail/page',
        };
    },
    mounted() {
        const _this = this;
        Util.queryTable(_this, _this.url, { params: this.settle });
    },
    methods: {
        handleAudit() {
            this.$refs.PickupReturnAudit.show(this.form.code, this.baseUrl);
        },
        mTwo: Util.mTwo,
        mCount: Util.mCount,
    },
};
</script>
